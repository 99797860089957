import logger from '@/utils/logger';
import { type ActionFunctionArgs } from '@remix-run/node';
import { type Navigation, useFetcher, useNavigation } from '@remix-run/react';
import LoadingSpinner from 'apps/caredash/app/components/LoadingSpinner';
import { authenticator } from 'apps/caredash/app/services/auth.server';
import { type ReactElement } from 'react';
import { returnToCookie } from '~/services/session.server';

export const meta: MetaFunction = () => {
  return [{ title: 'Login' }];
};

export const action = async ({ request }: ActionFunctionArgs) => login(request);

// commented out because logout logs you back in, if the loader is enabled on this
// export const loader = async ({ request }: LoaderFunctionArgs) => login(request);

const login = async (request: Request) => {
  logger.info({
    client: 'auth',
    action: 'login',
  });
  const url = new URL(request.url);
  const returnTo = url.searchParams.get('next');

  try {
    return await authenticator.authenticate('cognito', request, {
      successRedirect: returnTo ?? '/',
      failureRedirect: '/',
    });
  } catch (error) {
    if (!returnTo) throw error;
    if (error instanceof Response && isRedirect(error)) {
      error.headers.append('Set-Cookie', await returnToCookie.serialize(returnTo));
      return error;
    }
    throw error;
  }
};

function isRedirect(response: Response) {
  logger.info({
    client: 'auth',
    action: 'redirect',
  });
  if (response.status < 300 || response.status >= 400) return false;
  return response.headers.has('Location');
}

export default function Login(): ReactElement {
  const fetcher = useFetcher<typeof action>();
  const navigation: Navigation = useNavigation();
  return (
    <fetcher.Form className="flex min-h-full flex-col items-center justify-center" method="post">
      <div className="relative">
        <div className="mb-3 text-center text-blue-800">
          <i style={{ fontFamily: 'serif', fontSize: '1.1rem' }}>my</i>
          <b>Laurel</b>
        </div>
        <button className="btn-primary" type="submit">
          Login with Google
        </button>
      </div>
      <div className="mt-32 z-10 absolute">{navigation.state !== 'idle' && <LoadingSpinner />}</div>
    </fetcher.Form>
  );
}
